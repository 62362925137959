import './App.css';
import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

//import { LocaleProvider, Form, Input, Select, Checkbox, Radio, Button, Icon, Modal, Row, Col } from 'antd';
import { Form, Input, Select, Checkbox, Radio, Button, Icon, Modal, Row, Col } from 'antd';
import { debug, warning } from './Utilities'
//import $ from "jquery";
import axios from 'axios';

const InputGroup = Input.Group;

//import it_IT from 'antd/lib/locale-provider/it_IT';
//import 'moment/locale/it';

var qs = require('qs');

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

const urlLogo = 'https://siti.accmed.org/logo/accmed.svg';
const b_it = 'images/IT.png';
const b_en = 'images/EN.png';

const recaptcha_publickey = '6LeZ5wUAAAAAAEFuPxN1nma9lrIo57wi4XVWlVVT';

class App extends React.Component {

  constructor(props) {
    super(props);

    let lang = window.navigator.userLanguage || window.navigator.language;
    lang = lang.indexOf("it")!==-1 ? 'IT' : 'EN';
    if (this.props.from==='opbg') lang = 'IT';

    //let start = this.props.ky ? true : false
    let start = (this.props.cf || this.props.email) ? true : false

    this.state = {
      lang:lang,
      start:start,
      nextstep:false,
      age:false,
      ha_cf:false,
      ok_nocf:false,
      attenzione_nocf:false,
    };

    initLang(lang)
  }

  start = () => {
    if (!(this.state.ha_cf && this.state.age)) {
      warning(_l.rispondi);
      return;
    }
    if (this.state.age===1) {
      warning(_l.under18);
      return;
    }
    if (this.state.ha_cf===2 && !this.state.ok_nocf) {
      this.setState({attenzione_nocf:true});
      return;
    }
    if (this.state.ha_cf && this.state.age===2) this.setState({start:true});
  }

  nextStep = () => {
    this.setState({nextstep:true});
  }

  getAge = (e) => {
    this.setState({age:e.target.value});
  }

  getCF = (e) => {
    this.setState({ha_cf:e.target.value});
  }

  getNoCF = (e) => {
    this.setState({ok_nocf:e.target.checked});
  }

  changeLang = () => {
    let lang = this.state.lang==='IT' ? 'EN' : 'IT'
    initLang(lang);
    this.setState({lang:lang});
  }

  render() {

    const WrappedChangeEmailForm = Form.create()(ChangeEmailForm);
    const WrappedForgotLinkForm = Form.create()(ForgotLinkForm);
    const WrappedRegistrationForm = Form.create()(RegistrationForm);
    const WrappedForgotUsernameForm = Form.create()(ForgotUsernameForm);
    const WrappedForgotPasswordForm = Form.create()(ForgotPasswordForm);

    let html = ''

    if (this.props.azione==='email') html = <WrappedChangeEmailForm from={this.props.from} />

    if (this.props.azione==='link') html = <WrappedForgotLinkForm />

    if (this.props.azione==='username') html = <WrappedForgotUsernameForm />

    if (this.props.azione==='password') html = <WrappedForgotPasswordForm token={this.props.ky} />

    if (this.props.azione==='confirm') html = <RegistrationConfirm token={this.props.ky} />

    if (this.props.azione==='signup' && this.state.start) html = <WrappedRegistrationForm ha_cf={this.state.ha_cf} cf={this.props.cf} ky={this.props.ky} email={this.props.email} from={this.props.from} moodle={this.props.moodle} />

    if (this.props.azione==='signup' && !this.state.start) {

      if (!this.state.nextstep) {

        let benvenuto = lang==='IT' ?
          <React.Fragment>
            <h4><strong>Benvenuta/o nella procedura di creazione dell'account AccMed Online, potrai utilizzare questo account per accedere a tutti i servizi digitali di Accademia Nazionale di Medicina.</strong></h4>
            <h4>Per completare la registrazione e attivare i nostri servizi online, devi seguire i seguenti passi:</h4>
            <ol>
              <li>
                <strong>Compila i campi nelle varie sezioni del modulo di registrazione</strong>, con particolare attenzione ai campi obbligatori, che sono contrassegnati con un asterisco rosso.
              </li>
              <li>
                <strong>Prendi visione dell’informativa sulla privacy</strong>, indica la presa visione e fornisci i consensi che ritieni opportuni.
              </li>
              <li>
                <strong>Invia il il modulo online.</strong>
              </li>
              <li>
                <strong>Segui le istruzioni contenute nella mail di conferma che riceverai da Accademia</strong> per verificare l'indirizzo mail da te indicato e completare la registrazione.
              </li>
            </ol>
            <h4>In caso di problemi di registrazione</h4>
            <ul>
              <li>Se ti sei già registrata/o e <strong>non hai ricevuto l'email di conferma</strong>, <a onClick={()=>{document.location.href="?act=link"}}>fai click qui.</a></li>
              <li>Se ti sei già registrata/o e <strong>hai dimenticato la password</strong>, <a onClick={()=>{document.location.href="?act=password"}}>fai click qui per recuperarla.</a></li>
              <li><strong>Se non sei sicura/o</strong>, prova a registrarti usando il tuo codice fiscale e la tua email, se il sistema ti riconosce riceverai le istruzioni per recuperare i tuoi dati personali di accesso.</li>
            </ul>
            {/*
            <h4>Il sito è ottimizzato per funzionare con Firefox, Safari, Chrome, ed Explorer 9 o superiori.</h4>
            <h4>L’accesso ai servizi online di Accademia Nazionale di Medicina è riservato ai maggiori di 18 anni. Per le attività accreditate ECM, i crediti sono erogati esclusivamente a operatori sanitari dotati di CF italiano.</h4>
            */}
          </React.Fragment>
          :
          <React.Fragment>
            <h4><strong>Welcome to the registration process to the online services of Accademia Nazionale di Medicina.</strong></h4>
            <h4>To complete the registration and access our online services, you need to follow these steps:</h4>
            <ol>
              <li>
                <strong>Fill in the fields in the various sections of the registration form</strong>, with particular attention to the mandatory fields, which are marked with a red asterisk.
              </li>
              <li>
                <strong>Read the privacy policy</strong>, confirm that you have read it and give the consents you deem appropriate.
              </li>
              <li>
                <strong>Submit the online registration form.</strong>
              </li>
              <li>
                <strong>Follow the instructions contained in the confirmation email you will receive from Accademia</strong> to verify the email address you provided and complete the registration process.
              </li>
            </ol>
            <h4>In case of registration problems:</h4>
            <ul>
              <li>If you have already registered and have <strong>not received the confirmation email</strong>, <a onClick={()=>{document.location.href="?act=link"}}>click here.</a></li>
              <li>If you have already registered and have <strong>forgotten your password</strong>, <a onClick={()=>{document.location.href="?act=password"}}>click here to recover it.</a></li>
              <li><strong>If you are not sure</strong>, try to register using your social security number and your email, if the system recognizes you, you will receive instructions to recover your account.</li>
            </ul>
            {/*
            <h4>The site is optimized to work with Firefox, Safari, and Chrome.</h4>
            <h4>Access to the online services of the National Academy of Medicine is reserved to people over 18 years of age. For ECM accredited activities, credits are provided exclusively to healthcare professionals with an Italian CF.</h4>
            */}
          </React.Fragment>

        html =
          <React.Fragment>
            <h2>{_l.creazione_profilo}</h2>
            {benvenuto}
            <Button type="primary" onClick={this.nextStep}>{_l.procedi}</Button>
          </React.Fragment>

      } else {

        let attenzione_nocf = ''
        if (this.state.attenzione_nocf)
          attenzione_nocf =
          <React.Fragment>
            <strong>{_l.attenzione_nocf}</strong>
            <br /><br />
            <Checkbox onChange={this.getNoCF}>{_l.dichiaro_nocf}</Checkbox>
            <br /><br />
          </React.Fragment>

        html =
          <React.Fragment>
            <h2>{_l.creazione_profilo}</h2>
            <h4><strong>{_l.rispondi}</strong></h4>
            <br />
            <div dangerouslySetInnerHTML={createMarkup(_l.hai_eta)} />
            <Radio.Group onChange={this.getAge}>
              <Radio value={2}>{_l.o18}</Radio>
              <Radio value={1}>{_l.u18}</Radio>
            </Radio.Group>
            <br /><br />
            <div dangerouslySetInnerHTML={createMarkup(_l.hai_cf)} />
            <Radio.Group onChange={this.getCF}>
              <Radio value={1}>{_l.si}</Radio>
              <Radio value={2}>{_l.no}</Radio>
            </Radio.Group>
            <br /><br />
            {attenzione_nocf}
            <Button type="primary" onClick={this.start}>{_l.procedi}</Button>
            <br /><br />
            <div className="ottimizzato" dangerouslySetInnerHTML={createMarkup(_l.accesso)} />
            <br />
            <div className="ottimizzato">{_l.ottimizzato}</div>
        </React.Fragment>

      }
    }
    /*
    return (
      <LocaleProvider locale={it_IT}>
        <Row>
          <Col xs={24} md={24} xl={{span:14, offset:5}}>
            {this.state.lang==='IT' ? <div style={{textAlign:'right'}} onClick={this.changeLang}><img alt="en" className="bandiera" src={b_en} /></div> : <div style={{textAlign:'right'}} onClick={this.changeLang}><img alt="it" className="bandiera" src={b_it} /></div>}
            {html}
          </Col>
        </Row>
      </LocaleProvider>
    );
    */
    if (this.props.from==='opbg') {
      return (
        <Row>
          <Col xs={24} md={24} xl={{span:14, offset:5}}>
            {html}
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col xs={24} md={24} xl={{span:14, offset:5}}>
            {this.state.lang==='IT' ? <div style={{textAlign:'right'}} onClick={this.changeLang}><img alt="en" className="bandiera" src={b_en} /></div> : <div style={{textAlign:'right'}} onClick={this.changeLang}><img alt="it" className="bandiera" src={b_it} /></div>}
            {html}
          </Col>
        </Row>
      );
    }
  }
}

class RegistrationForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user:false,
      new_user:true,
      saving: false,
      stato_verifica: -1,
      stato_registrazione: 0,
      confirmDirty: false,
      confirmEmailDirty: false,
      confirmEmailPECDirty: false,
      show_informativa: false,
      show_condizioni: false,
      mod_privacy: true,
      mod_password: false,
      privacy: false,
      promo:false,
      abitudini:false,
      condizioni:false,
    };
  }

  componentDidUpdate() {

   //$(".input_email").on("cut copy paste",function(e) { e.preventDefault(); });

   let el1 = document.getElementById("conferma_email")
   if (el1) el1.addEventListener("paste", function(e) { e.preventDefault(); })
   let el2 = document.getElementById("conferma_email_pec")
   if (el2) el2.addEventListener("paste", function(e) { e.preventDefault(); })

  }

  componentDidMount() {
    this.getUserData()
  }

  getNazioni = () => {

    axios({
      method:'post',
      url:'services/dispatch.php',
      data: qs.stringify({servizio:'get_nazioni'}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data) {
        let stati = response.data
        let select_stati = []
        for (let ii=0; ii<stati.length; ii++) {
          select_stati[ii] = <Option key={ii} value={stati[ii].sigla}>{stati[ii].nome}</Option>
        }
        this.setState({select_stati:select_stati})
      }
    }.bind(this))
    .catch(function (error) {
      warning(_l.ops, error.message);
      if (debug) console.log(error);
    });
  }

  getProvince = () => {

    axios({
      method:'post',
      url:'services/dispatch.php',
      data: qs.stringify({servizio:'get_province'}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data) {
        let select_province = []
        let province = response.data
        for (let ii=0; ii<province.length; ii++) {
          select_province[ii] = <Option key={ii} value={province[ii].sigla}>{province[ii].nome}</Option>
        }
        this.setState({select_province:select_province})
        if (this.state.user) this.getComuni()
      }
    }.bind(this))
    .catch(function (error) {
      warning(_l.ops, error.message);
      if (debug) console.log(error);
    });
  }

  getComuni = () => {

    axios({
      method:'post',
      url:'services/dispatch.php',
      data: qs.stringify({servizio:'get_comuni', provincia:this.props.form.getFieldValue('provincia')}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data) {
        let select_comuni = []
        let comuni = response.data
        for (let ii=0; ii<comuni.length; ii++) {
          select_comuni[ii] = <Option key={ii} value={comuni[ii].nome}>{comuni[ii].nome}</Option>
        }
        this.setState({select_comuni:select_comuni})
      }
    }.bind(this))
    .catch(function (error) {
      warning(_l.ops, error.message);
      if (debug) console.log(error);
    });

  }

  getProvinceEnte = () => {

    axios({
      method:'post',
      url:'services/dispatch.php',
      data: qs.stringify({servizio:'get_province'}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data) {
        let select_province = []
        let province = response.data
        for (let ii=0; ii<province.length; ii++) {
          select_province[ii] = <Option key={ii} value={province[ii].sigla}>{province[ii].nome}</Option>
        }
        this.setState({select_province_ente:select_province})
        if (this.state.user) this.getComuniEnte()
      }
    }.bind(this))
    .catch(function (error) {
      warning(_l.ops, error.message);
      if (debug) console.log(error);
    });
  }

  getComuniEnte = () => {

    axios({
      method:'post',
      url:'services/dispatch.php',
      data: qs.stringify({servizio:'get_comuni', provincia:this.props.form.getFieldValue('ente_provincia')}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data) {
        let select_comuni = []
        let comuni = response.data
        for (let ii=0; ii<comuni.length; ii++) {
          select_comuni[ii] = <Option key={ii} value={comuni[ii].nome}>{comuni[ii].nome}</Option>
        }
        this.setState({select_comuni_ente:select_comuni})
      }
    }.bind(this))
    .catch(function (error) {
      warning(_l.ops, error.message);
      if (debug) console.log(error);
    });

  }

  getProfessioni = () => {

    axios({
      method:'post',
      url:'services/dispatch.php',
      data: qs.stringify({servizio:'get_professioni'}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data) {
        let select_prof = []
        let prof = response.data
        for (let ii=0; ii<prof.length; ii++) {
          select_prof[ii] = <Option key={ii} value={prof[ii].codice}>{prof[ii].nome}</Option>
        }
        this.setState({select_prof:select_prof})
        if (this.state.user) this.getDiscipline()
      }
    }.bind(this))
    .catch(function (error) {
      warning(_l.ops, error.message);
      if (debug) console.log(error);
    });
  }

  getDiscipline = () => {

    if (!this.props.form.getFieldValue('professione')) return;

    axios({
      method:'post',
      url:'services/dispatch.php',
      data: qs.stringify({servizio:'get_discipline', professione:this.props.form.getFieldValue('professione')}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data) {
        let select_disc = []
        let disc = response.data
        for (let ii=0; ii<disc.length; ii++) {
          select_disc[ii] = <Option key={ii} value={disc[ii].codice}>{disc[ii].nome}</Option>
        }
        this.setState({select_disc:select_disc})
      }
    }.bind(this))
    .catch(function (error) {
      warning(_l.ops, error.message);
      if (debug) console.log(error);
    });
  }

  getPosizione = () => {

    axios({
      method:'post',
      url:'services/dispatch.php',
      data: qs.stringify({servizio:'get_posizione'}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data) {
        let select_posiz = []
        let posiz = response.data
        for (let ii=0; ii<posiz.length; ii++) {
          select_posiz[ii] = <Option key={ii} value={posiz[ii].id}>{posiz[ii].nome}</Option>
        }
        this.setState({select_posiz:select_posiz})
      }
    }.bind(this))
    .catch(function (error) {
      warning(_l.ops, error.message);
      if (debug) console.log(error);
    });
  }

  handleChangeProf = () => {

    if (this.props.form.getFieldValue('disciplina1')) this.props.form.setFieldsValue({disciplina1:''})
    if (this.props.form.getFieldValue('disciplina2')) this.props.form.setFieldsValue({disciplina2:''})
    if (this.props.form.getFieldValue('disciplina3')) this.props.form.setFieldsValue({disciplina3:''})
  }

  validateChangeProf = (rule, value, callback) => {

    if (value) this.getDiscipline()
    if (!value) callback(_l.campo_obbligatorio);
    callback();
  }

  handleChangeProvincia = (rule, value, callback) => {

    if (this.props.form.getFieldValue('comune')) this.props.form.setFieldsValue({comune:''})
  }

  validateChangeProvincia = (rule, value, callback) => {

    if (value) this.getComuni()
    if (!value) callback(_l.campo_obbligatorio);
    callback();
  }

  validateChangeProvinciaEnte = (rule, value, callback) => {

    if (value) this.getComuniEnte()
    //if (!value) callback(_l.campo_obbligatorio);
    callback();
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (this.state.stato_verifica!==0) {
        if (!err) {
          this.verificaStep1(values)
        }
      } else {
        if (!err) this.setUserData(values);
      }
    });
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback(_l.password_coincidere);
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {

    //let regole = new RegExp(/^(?=.*\d.*\d.*\d)(?=.*[A-Za-z@#$%])[0-9a-zA-Z@#$%]{8,}$/);
    let regole = new RegExp(/^(?=.*\d.*\d)(?=.*[A-Za-z!?£$€&#@%])[0-9a-zA-Z!?£$€&#@%]{8,}$/);

    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['conferma_password'], { force: true });
    }
    if (value && !regole.exec(value))
      callback(_l.regole_password);
    else
      callback();
  }

  handleConfirmEmailBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmEmailDirty: this.state.confirmEmailDirty || !!value });
  }

  compareToFirstEmail = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('email')) {
      callback(_l.email_coincidere);
    } else {
/*
    axios({
      method:'post',
      url:'services/mailgun.php',
      data: qs.stringify({email:value}),
      responseType:'text'
    })
    .then(function(response) {
      if(response.data==true) {
        callback();
      } else {
        callback('questa email sembra non funzionare...');
      }
    })
    .catch(function (error) {
      callback();
      //warning(_l.ops, error.message);
      if (debug) console.log(error);
    });
*/
      callback();
    }
  }

  validateToNextEmail = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmEmailDirty) {
      form.validateFields(['conferma_email'], { force: true });
    }
    callback();
  }

  handleConfirmEmailPECBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmEmailDirty: this.state.confirmEmailPECDirty || !!value });
  }

  compareToFirstEmailPEC = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('email_pec')) {
      callback('le email devono coincidere');
    } else {
      callback();
    }
  }

  validateToNextEmailPEC = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmEmailPECDirty) {
      form.validateFields(['conferma_email_pec'], { force: true });
    }
    callback();
  }

  validateCF = (rule, value, callback) => {

    if (!value) {
      callback()
      return
    }

  	let cf = value.toUpperCase();
    if (value.length!==16)
      callback('Il codice fiscale deve essere lungo 16 caratteri');
    else {

      //check maggiore età
      var anno_utente = parseInt(cf.substring(6,8))+1900;
      var d = new Date();
      var anno_attuale = parseInt(d.getFullYear());
      if ((anno_utente+100)<anno_attuale) anno_utente = anno_utente+100;
      if (anno_utente>anno_attuale-18) {
        callback('Per iscriversi è necessario essere maggiorenni. Dai dati inseriti, risulti essere minorenne.')
        return
      }

    	var map = [1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 2, 4, 18, 20, 11, 3, 6, 8, 12, 14, 16, 10, 22, 25, 24, 23];
    	var s = 0;
    	for(var i = 0; i < 15; i++){
    		var c = cf.charCodeAt(i);
    		if ( c < 65 ) c = c - 48; else c = c - 55;
    		if( i % 2 == 0 ) s += map[c]; else s += c < 10? c : c - 10;
    	}
    	var atteso = String.fromCharCode(65 + s % 26);
    	if( atteso != cf.charAt(15) )
        callback ('Il codice fiscale non è corretto');
      else
        callback();
    }
  }

  validatePrivacy = (rule, value, callback) => {

    if (!value) {
      callback(_l.conferma_obbligatoria)
      return
    }
    callback();
  }

  validatePromo = (rule, value, callback) => {

    if (!value) {
      callback(_l.presta_nega)
      return
    }
    callback();
  }

  validateAbitudini = (rule, value, callback) => {

    if (!value) {
      callback(_l.presta_nega)
      return
    }
    callback();
  }

  validateTelefono = (rule, value, callback) => {

    if (isNaN(value)) {
      callback(_l.telefono_no)
      return
    }
    callback();
  }

  validateNome = (rule, value, callback) => {

    let regole = new RegExp(/^[a-zA-Zàèìòù '-]+$/);

    const form = this.props.form;

    if (value && !regole.exec(value))
      callback(_l.regole_nome);
    else
      callback();
  }

  backToStep1 = (e) => {
    e.preventDefault()
    this.setState({stato_verifica: -1});
  }

  handleOk = () => {
    this.setState({show_informativa: false, show_condizioni:false});
  }

  handleCancel = () => {
    this.setState({show_informativa: false, show_condizioni:false});
  }

  verificaStep1 = (data) => {

    axios({
      method:'post',
      url:'services/mailgun.php',
      data: qs.stringify({email:data.email}),
      responseType:'text'
    })
    .then(function(response) {
      if(response.data==true) {
        this.verificaUtente(data);
      } else {
        warning('L’ email che hai inserito sembra non funzionare...');
      }
    }.bind(this))
    .catch(function (error) {
      warning(_l.ops, error.message);
      if (debug) console.log(error);
    });
  }

  verificaUtente = (data) => {

    if (this.state.saving) return;
    this.setState({saving:true})

    if (debug) console.log(data);

    axios({
      method:'post',
      url:'services/dispatch.php',
      data: qs.stringify({servizio:'verifica_utente', data:data}),
      responseType:'text'
    })
    .then(function(response) {
      //cf o email mascherati
      let data_mask = ''
      let stato_verifica = response.data
      if (isNaN(response.data)) {
        let d = response.data.split(',')
        stato_verifica = parseInt(d[0])
        data_mask = d[1]
      }
      this.setState({data_step1:data, stato_verifica:stato_verifica, data_mask:data_mask, saving:false});
      if (response.data===-1) warning(_l.ops + _l.riprova);
    }.bind(this))
    .catch(function (error) {
      warning(_l.ops, error.message);
      if (debug) console.log(error);
    })
  }

  emailAssistenza = (p) => {

    if (this.state.saving) return;
    this.setState({saving:true})

    axios({
      method:'post',
      url:'services/dispatch.php',
      data: qs.stringify({servizio:'email_assistenza', data:this.state.data_step1, problema:p}),
      responseType:'text'
    })
    .then(function(response) {
      warning(_l.email_assistenza_ok);
      this.setState({stato_verifica:666, saving:false})
    }.bind(this))
    .catch(function (error) {
      warning(_l.ops, error.message);
      if (debug) console.log(error);
    })

  }

  getUserData = () => {

    axios({
      method:'post',
      url:'services/dispatch.php',
      data: qs.stringify({servizio:'get_dati_utente', cf:this.props.cf, email:this.props.email, key:this.props.ky}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data) {

        let user = response.data

        if (debug) console.log(user)

        if (user.ente_nome) {
          let ente_a = user.ente_nome.split(';');
          user.ente_nome = ente_a[0];
          user.ente_divisione = ente_a[1];
        }

        let promo = user.promo ? '1' : '0';
        let abitudini = user.abitudini ? '1' : '0';

        let mod_privacy = true; //07 03 2023 [dovrebbe servire a mostrare privacy se scaduta ma non funge, da capire] (promo && abitudini) ? false : true;
        this.setState({user:user, new_user:false, mod_privacy:mod_privacy, condizioni:true, privacy:true, promo:promo, abitudini:abitudini, stato_verifica:0})
      }
    }.bind(this))
    .catch(function (error) {
      warning(_l.ops, error.message);
      if (debug) console.log(error);
    })
    .then(function () {
      this.getNazioni()
      this.getProvince()
      this.getProvinceEnte()
      this.getProfessioni()
      this.getPosizione()
    }.bind(this));

  }

  setUserData = (data) => {

    if (this.state.saving) return;
    this.setState({saving:true})

    data.origine = this.props.from
    data.mod_privacy = this.state.mod_privacy ? 1 : 0

    if (!this.state.new_user) {

      //aggiungo alcuni dati di contorno non editabili

      data.editing = 1
      data.nome = this.state.user.nome
      data.cognome = this.state.user.cognome
      data.codice_fiscale = this.state.user.codice_fiscale

    }

    if (debug) console.log(data);

    axios({
      method:'post',
      url:'services/dispatch.php',
      data: qs.stringify({servizio:'set_dati_utente', data:data, id:this.state.user.id}),
      responseType:'text'
    })
    .then(function(response) {
      if (response.data==='success') {
        this.setState({saving:false, stato_registrazione:2})
        //redirect per fad
        if (this.props.moodle) window.top.location.href = 'https://fad.accmed.org/course/view.php?id='+this.props.moodle
      } else {
        this.setState({saving:false, stato_registrazione:1})
      }
    }.bind(this))
    .catch(function (error) {
      warning(_l.ops, error.message);
      if (debug) console.log(error);
    })

  }

  render() {

    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 17,
          offset: 7,
        },
      },
    };
    /*
    const responseFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
      },
    };
    */

    let saving = false
    if (this.state.saving) saving = <Icon type="loading" theme="outlined" />

    let input =    (this.state.stato_verifica===0) ? <Input disabled /> : <Input />
    let input_e =  (this.state.stato_verifica===0) ? <Input disabled /> : <Input onBlur={this.handleConfirmEmailBlur} />
    let input_p1 = (this.state.stato_verifica===0) && this.state.new_user ? <Input type="password" disabled /> : <Input.Password type="password" autoComplete="new-password" />
    let input_p2 = (this.state.stato_verifica===0) && this.state.new_user ? <Input type="password" disabled /> : <Input.Password type="password" onBlur={this.handleConfirmBlur} />

    if (!this.state.new_user && !this.state.mod_password) input_p1 = <Input type="password" placeholder="********" disabled />

    let nome_cognome = ''
    let codice_fiscale = ''
    let label_gestione_profilo = <h2>{_l.gestione_profilo}</h2>

    let email = ''
    let conferma_email = ''

    if (!this.state.new_user) {

      nome_cognome =
        <React.Fragment>
        <FormItem {...formItemLayout} label={_l.nome} >
          {getFieldDecorator('nome', {
            initialValue:this.state.user.nome,
          })(
            <Input disabled />
          )}
        </FormItem>
        <FormItem {...formItemLayout} label={_l.cognome} >
          {getFieldDecorator('cognome', {
            initialValue:this.state.user.cognome,
          })(
            <Input disabled />
          )}
        </FormItem>
        </React.Fragment>

      if (this.props.ha_cf!=2)
        codice_fiscale =
          <FormItem {...formItemLayout} label={_l.cf} >
            {getFieldDecorator('codice_fiscale', {
              initialValue:this.state.user.codice_fiscale,
            })(
              <Input disabled />
            )}
          </FormItem>

      email =
        <FormItem {...formItemLayout} label="E-mail" >
          {getFieldDecorator('email', {
            initialValue:this.state.user.email,
          })(
            <Input disabled />
          )}
        </FormItem>

    } else {

      label_gestione_profilo = <h2>{_l.creazione_profilo}</h2>

      nome_cognome =
        <React.Fragment>
        <FormItem {...formItemLayout} label={_l.nome} >
          {getFieldDecorator('nome', {
            initialValue:'',
            rules: [{ required: true, message: _l.campo_obbligatorio, whitespace: true }, { validator: this.validateNome }],
          })(
            {...input}
          )}
        </FormItem>
        <FormItem {...formItemLayout} label={_l.cognome} >
          {getFieldDecorator('cognome', {
            initialValue:'',
            rules: [{ required: true, message: _l.campo_obbligatorio, whitespace: true }, { validator: this.validateNome }],
          })(
            {...input}
          )}
        </FormItem>
        </React.Fragment>

      if (this.props.ha_cf!=2)
        codice_fiscale =
          <FormItem {...formItemLayout} label={_l.cf} >
            {getFieldDecorator('codice_fiscale', {
              initialValue:'',
              rules: [{
                required: true, message: _l.campo_obbligatorio,
              }, {
                validator: this.validateCF,
              }],
            })(
              {...input}
            )}
          </FormItem>

      email =
        <FormItem {...formItemLayout} label="E-mail" >
          {getFieldDecorator('email', {
            rules: [{
              type: 'email', message: _l.email_non_valida,
            }, {
              required: true, message: _l.campo_obbligatorio,
            }, {
              validator: this.validateToNextEmail,
            }],
          })(
            {...input}
          )}
        </FormItem>

      if (this.props.form.getFieldValue('email'))
        conferma_email =
          <FormItem {...formItemLayout} label={_l.conferma_email} >
            {getFieldDecorator('conferma_email', {
              rules: [{
                required: true, message: _l.campo_obbligatorio,
              }, {
                validator: this.compareToFirstEmail,
              }],
            })(
            {...input_e}
            )}
          </FormItem>
    }

    let password =
      <FormItem {...formItemLayout} label="Password" >
        {getFieldDecorator('password', {
          rules: [{
            required: this.state.new_user, message: _l.campo_obbligatorio,
          }, {
            validator: this.validateToNextPassword,
          }],
        })(
          {...input_p1}
        )}
      </FormItem>

    let conferma_password = ''
    if (this.props.form.getFieldValue('password'))
      conferma_password =
        <FormItem {...formItemLayout} label={_l.conferma_password} >
          {getFieldDecorator('conferma_password', {
            rules: [{
              required: this.state.new_user, message: _l.campo_obbligatorio,
            }, {
              validator: this.compareToFirstPassword,
            }],
          })(
            {...input_p2}
          )}
        </FormItem>

    let modifica_password = ''
    if (!this.state.new_user && !this.state.mod_password)
      modifica_password =
        <FormItem {...tailFormItemLayout}>
          <Button className="rosso" onClick={ () => this.setState({mod_password: true}) }>{_l.modifica_password}</Button>
        </FormItem>

    let bottone_continua = ''
    if ((this.state.stato_verifica===-1) || (this.state.stato_verifica===1) || (this.state.stato_verifica===2) || (this.state.stato_verifica===7) || (this.state.stato_verifica===8) || (this.state.stato_verifica===88)) {
      bottone_continua =
        <FormItem {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">{saving} {_l.continua}</Button>
        </FormItem>
    }


    if ((this.state.stato_verifica===0) && (this.state.stato_registrazione===0) && this.state.new_user) {
      bottone_continua =
        <FormItem {...tailFormItemLayout}>
          <Button onClick={this.backToStep1}>{_l.indietro}</Button>
        </FormItem>
    }

    let esito_verifica = ''

    //utente già con account
    if (this.state.stato_verifica===1)
      esito_verifica = lang==='IT' ?
        <p className="esito_ok">
          Gentile utente, l’indirizzo email da te specificato è già utilizzato da un account AccMed Online.<br /><br />
          Se non ricordi la password ma hai accesso all’indirizzo email indicato, puoi eseguire la procedura di recupero password facendo <a onClick={()=>{document.location.href="?act=password"}}>click qui.</a><br /><br />
          Per qualsiasi informazioni aggiuntiva puoi rivolgerti al nostro servizio di assistenza inviando una mail a <a href="mailto:assistenza@accmed.org">assistenza@accmed.org</a><br />
        </p>
        :
        <p className="esito_ok">
          Dear User, the email address you specified is already used by an AccMed Online account.<br /><br />
          If you don't remember your password but you have access to the email address indicated, you can perform the password recovery procedure by clicking here.<br /><br />
          For any additional information, you can contact our assistance service by sending an email to <a onClick={()=>{document.location.href="?act=password"}}>assistenza@accmed.org</a><br />
        </p>

    //account con CF uguale ma email diversa
    if (this.state.stato_verifica===2)
      esito_verifica = lang==='IT' ?
        <p className="esito_ok">
          Gentile utente, il Codice Fiscale da te indicato è già usato da un account presente sui nostri server.<br />
          Tale account è associato all’indirizzo email: {this.state.data_mask}<br /><br />
          Se non ricordi la password ma hai accesso all’indirizzo mail indicato, <a onClick={()=>{document.location.href="?act=password"}}>puoi eseguire la procedura di recupero password facendo click qui.</a><br /><br />
          Se non hai più accesso all’indirizzo email indicato, devi rivolgerti al nostro servizio di assistenza per aggiornare l’indirizzo email associato all’account.<br />
          Invia una mail a <a href="mailto:assistenza@accmed.org">assistenza@accmed.org</a> contenente i seguenti dati personali (nome, cognome, CF, Luogo e Data di Nascita), una scansione del tuo CF o CI, un numero di cellulare (per poterti contattare) e il nuovo indirizzo mail che vuoi associare all’account AccMed Online in questione.<br />
          Il nostro personale provvederà a contattarti e, confermata la tua identità e volontà di procedere, eseguirà il cambio di indirizzo dell’indirizzo email associato all’account.<br />
        </p>
        :
        <p className="esito_ok">
          The Tax Code you provided is already associated with an account on our servers.<br />
          The account is associated with the email address: {this.state.data_mask}<br /> <br />
          If you have access to this email address but do not remember the password, <a onClick={()=>{document.location.href="?act=password"}}> click here</a> and indicate the full email address.<br /><br />
          If you do not have access to this email address, send an email to <a href="mailto:assistenza@accmed.org">assistenza@accmed.org</a> containing your personal data (Name, Surname, Tax Code, Place and Date of Birth), the legible scan of your ID Card, a mobile number, and the email address you want to associate with the account in question.<br />
          Our staff will contact you and, having confirmed your identity and willingness to proceed, we will change the reference email address.
        </p>

    //utente già registrato ma non ancora attivato
    if (this.state.stato_verifica===3)
      esito_verifica = lang==='IT' ?
        <p className="esito_ok">
          Gentile utente, sei già presente nel nostro sistema ma non hai ancora attivato la registrazione.<br />
          Se non hai ricevuto il link di conferma registrazione <a onClick={()=>{document.location.href="?act=link"}}>fai click qui.</a><br />
        </p>
        :
        <p className="esito_ok">
          You are already present in our system but you have not yet activated your account.<br />
          If you have not received the link to confirm your registration <a onClick={()=>{document.location.href="?act=link"}}>click here.</a><br />
        </p>

    //PF con CF e email uguali - no account
    if (this.state.stato_verifica===4)
      esito_verifica = lang==='IT' ?
        /*
        <p className="esito_ok">
          Gentile utente, il Codice Fiscale e l’indirizzo email da te specificati sono già associati a un profilo presente sui nostri server (probabilmente per via di una attività ECM svolta presso di noi o uno dei nostri partner), ma il relativo account AccMed Online non è ancora attivo.<br /><br />
          Controlla la tua casella di posta: a breve riceverai un messaggio automatico per attivare il tuo account. Per favore, segui le istruzioni in esso contenute.<br /><br />
          Se non hai ricevuto la mail di attivazione <a onClick={()=>{document.location.href="?act=link"}}>fai click qui.</a><br />
        </p>
        :
        <p className="esito_ok">
          Your data is already in our database (probably due to a CME activity carried out by us or one of our partners), but your account is not yet active.<br />
          Check your inbox: you will soon receive an automatic message to activate your account. Please, follow the instructions contained therein.<br />
          If you have not received the activation email <a onClick={()=>{document.location.href="?act=link"}}>click here.</a><br />
        </p>
        */
        <p className="esito_ok">
          <strong>Registrazione completata con successo.</strong><br /><br />
          Controlla la casella di posta: a breve riceverai un messaggio automatico con richiesta di conferma registrazione. Per favore, segui le istruzioni in esso contenute.<br />
          Se non trovi la mail di conferma nella tua casella di posta elettronica, ti preghiamo di controllare con attenzione <strong>nella cartella SPAM.</strong><br />
          Se non hai ricevuto la mail di conferma <a onClick={()=>{document.location.href="?act=link"}}>fai click qui.</a><br />
          Dopo aver inviato la conferma il tuo account sarà attivato e potrai accedere a tutti i servizi on line di ACCMED utilizzando i dati di accesso da te scelti, per iscriverti alle attività residenziali e FAD o per scaricare i materiali didattici messi a disposizione.<br />
          Se necessiti di assistenza invia un messaggio a <a href="mailto:assistenza@accmed.org">assistenza@accmed.org</a><br /><br />
          <strong>Nota bene</strong>: dopo l'accesso troverai alcuni tuoi dati già compilati (probabilmente per via di una attività ECM svolta presso di noi o uno dei nostri partner). Ti verrà chiesto di confermarli o aggiornarli.
        </p>
        :
        <p className="esito_ok">
          <strong>Registration successfully completed.</strong><br /><br />
          Check your inbox: soon you will receive an automatic message requesting the confirmation of the registration. Please, follow the instructions contained therein. <br />
          If you do not find the confirmation email in your inbox, please carefully check <strong>in the SPAM folder.</strong> <br />
          If you have not received the confirmation email <a onClick={()=>{document.location.href="?act=link"}}>click here.</a> <br />
          After submitting the confirmation, your account will be activated and you will be able to access all ACCMED online services using the access data you have specified, in particular you will be able to enroll in residential and e-learning activities and to download the didactic materials made available. <br />
          If you need assistance, please send a message to <a href="mailto:assistenza@accmed.org"> assistenza@accmed.org</a>
        </p>

    //PF con CF e email uguali - no account (precedentemente cancellato)
    if (this.state.stato_verifica===44)
      esito_verifica = lang==='IT' ?
        <p className="esito_ok">
          Gentile utente, il Codice Fiscale e l’indirizzo email da te specificati sono associati a un account presente sui nostri server in forma sospesa e minimizzata per via di una tua pregressa richiesta di cancellazione dei dati personali (abbiamo mantenuto eventuali dati minimi in ottemperanza ai regolamenti applicabili - quali l’ECM).<br /><br />
          Controlla la tua casella di posta: a breve riceverai un messaggio automatico per riattivare il tuo account. Per favore, segui le istruzioni in esso contenute.<br /><br />
          Se non hai ricevuto la mail di riattivazione <a onClick={()=>{document.location.href="?act=link"}}>fai click qui.</a><br /><br />
          L’informativa sulla privacy per gli utenti dei servizi online di AccMed è raggiungibile facendo <a onClick={()=>this.setState({show_informativa:true})}>click qui.</a><br />
        </p>
        :
        <p className="esito_ok">
          Dear user, the tax code and email address you specified are associated with an account already existing on our servers in a suspended and minimized form due to your previous request for deletion of personal data (we have kept any minimum data in compliance with applicable regulations - such as the CME).<br /><br />
          Check your mailbox: you will shortly receive an automatic message to reactivate your account. Please follow the instructions therein.<br /><br />
          If you have not received the reactivation email, <a onClick={()=>{document.location.href="?act=link"}}>click here.</a><br /><br />
          The privacy policy for users of AccMed online services can be reached by <a onClick={()=>this.setState({show_informativa:true})}>clicking here.</a><br />
        </p>

    //PF con CF uguale ma con nessuna email - no account
    if (this.state.stato_verifica===5)
      esito_verifica = lang==='IT' ?
        <p className="esito_ok">
          Gentile utente, il Codice Fiscale indicato è già presente nel nostro database (probabilmente per via di una attività ECM svolta presso di noi o uno dei nostri partner), ma associato ad un differente indirizzo email.<br />
          <a onClick={()=>this.emailAssistenza('email')}>Fai click qui</a> per inviare questi dati alla nostra assistenza, verrai contattato al più presto per risolvere il problema.<br />
          <a onClick={()=>this.setState({show_informativa:true})}>Fai click qui</a> per visualizzare l'informativa sulla privacy.
        </p>
        :
        <p className="esito_ok">
          The provided tax code is already present in our database (probably due to a CME activity carried out by us or one of our partners), but associated with a different email address.<br />
          <a onClick={()=>this.emailAssistenza('email')}>Click here</a> to send this data to our support team, you will be contacted as soon as possible to solve the problem.<br />
          <a onClick={()=>this.setState({show_informativa:true})}>Click here</a> to view the privacy policy.
        </p>

    //PF con CF diversa e email uguale - no account
    if (this.state.stato_verifica===6)
      esito_verifica = lang==='IT' ?
        <p className="esito_ok">
          Gentile utente, l'indirizzo email da te indicato è già usato da un profilo presente sui nostri server (probabilmente per via di una attività ECM svolta presso di noi o uno dei nostri partner), ma tale profilo è associato al codice fiscale: {this.state.data_mask}<br /><br />
          <a onClick={()=>this.emailAssistenza('cf')}>Fai click qui</a> per inviare i tuoi dati alla nostra assistenza, verrai contattato al più presto per risolvere il problema.<br />
          L’informativa sulla privacy per gli utenti dei servizi online di AccMed è raggiungibile facendo <a onClick={()=>this.setState({show_informativa:true})}>click qui.</a><br />
          Per qualsiasi informazioni aggiuntiva puoi rivolgerti al nostro servizio di assistenza inviando una mail a <a href="mailto:assistenza@accmed.org">assistenza@accmed.org</a><br />
        </p>
        :
        <p className="esito_ok">
          The email address you provided is already present in our database (probably due to a CME activity carried out by us or one of our partners), but associated with a different tax code.<br />
          <a onClick={()=>this.emailAssistenza('cf')}>Click here </a> to send this data to our support team, you will be contacted as soon as possible to resolve the problem.<br />
          <a onClick={()=>this.setState({show_informativa:true})}>Click here </a> to view the privacy policy.
        </p>

    //PF con CF diversa e email uguale - no account (precedentemente cancellato)
    if (this.state.stato_verifica===66)
      esito_verifica = lang==='IT' ?
        <p className="esito_ok">
          Gentile utente, l'indirizzo email da te indicato è già usato da un profilo presente sui nostri server in forma sospesa e minimizzata per via di una pregressa richiesta di cancellazione dei dati (abbiamo mantenuto eventuali dati minimi in ottemperanza ai regolamenti applicabili - quali l’ECM), ma tale profilo è associato al codice fiscale: {this.state.data_mask}<br /><br />
          <a onClick={()=>this.emailAssistenza('cf')}>Fai click qui</a> per inviare i tuoi dati alla nostra assistenza, verrai contattato al più presto per risolvere il problema.<br /><br />
          L’informativa sulla privacy per gli utenti dei servizi online di AccMed è raggiungibile facendo <a onClick={()=>this.setState({show_informativa:true})}>click qui.</a><br /><br />
          Per qualsiasi informazioni aggiuntiva puoi rivolgerti al nostro servizio di assistenza inviando una mail a <a href="mailto:assistenza@accmed.org">assistenza@accmed.org</a><br />
        </p>
        :
        <p className="esito_ok">
          Dear user, the email address you indicated is already used by a profile on our servers in a suspended and minimized form due to a previous request for deletion of data (we have kept any minimum data in compliance with applicable regulations - such as the ECM), but this profile is associated with the tax code: {this.state.data_mask}<br /><br />
          Click here to send your data to our assistance, you will be contacted as soon as possible to solve the problem.<br /><br />
          The privacy policy for users of AccMed online services can be reached by <a onClick={()=>this.setState({show_informativa:true})}>clicking here.</a><br /><br />
          For any additional information, you can contact our assistance service by sending an email to <a href="mailto:assistenza@accmed.org">assistenza@accmed.org</a><br />
        </p>

    //CF non coerente con nome e cognome
    if (this.state.stato_verifica===7)
      esito_verifica = lang==='IT' ?
        <p className="esito_ko">Nome e cognome non coerenti con il codice fiscale! Correggi per poter proseguire.</p>
        :
        <p className="esito_ko">Name and surname are not consisten with the tax code! Please fix the issue to proceed.</p>

    //PF con CF uguale e email diversa - no account
    if (this.state.stato_verifica===8)
      esito_verifica = lang==='IT' ?
        <p className="esito_ok">
          Gentile utente, il Codice Fiscale da te indicato è già usato da un profilo presente sui nostri server (probabilmente per via di una attività ECM svolta presso di noi o uno dei nostri partner), ma tale profilo è associato all’indirizzo email:<br /><br />
          {this.state.data_mask}<br /><br />
          Se riconosci questo indirizzo email come tuo ed è ancora attivo, puoi utilizzarlo in questa form per proseguire con la registrazione.<br /><br />
          Se non riconosci l’indirizzo o non lo vuoi usare, devi rivolgerti al nostro servizio di assistenza per aggiornare l’indirizzo email associato al profilo.<br />
          Invia una mail a <a href="mailto:assistenza@accmed.org">assistenza@accmed.org</a> contenente i seguenti dati personali (nome, cognome, CF, Luogo e Data di Nascita), una scansione del tuo CF o CI, un numero di cellulare (per poterti contattare) e il nuovo indirizzo mail che vuoi associare all’account in questione.<br />
          Il nostro personale provvederà a contattarti e, confermata la tua identità e volontà di procedere, eseguirà il cambio di indirizzo dell’indirizzo email associato all’account.<br /><br />
          L’informativa sulla privacy per gli utenti dei servizi online di AccMed è raggiungibile facendo <a onClick={()=>this.setState({show_informativa:true})}>click qui.</a><br />
        </p>
        :
        <p className="esito_ok">
          The Tax Code you provided is already present in our database (probably due to a CME activity carried out by us or one of our partners), but it is associated with this email address:<br /><br />
          {this.state.data_mask}<br /><br />
          If you recognize this email as yours, use it in this page to continue with the registration process.
          If you do not recognize it or do not want to use it, send an email to <a href="mailto:assistenza@accmed.org">assistance@accmed.org</a> containing your personal data (Name, Surname, Tax Code , Place and Date of Birth), the legible scan of your ID Card, a mobile number, and the email address you want to use.<br />
          Our staff will contact you and, having confirmed your identity and willingness to proceed, will change the reference email address.
        </p>

    //PF con CF uguale e email diversa - no account (precedentemente cancellato)
    if (this.state.stato_verifica===88)
      esito_verifica = lang==='IT' ?
        <p className="esito_ok">
          Gentile utente, il Codice Fiscale da te specificato è utilizzato da un account presente sui nostri server in forma sospesa e minimizzata per via di una pregressa richiesta di cancellazione dei dati (abbiamo mantenuto eventuali dati minimi in ottemperanza ai regolamenti applicabili - quali l’ECM). Tale account è associato all’indirizzo email:<br /><br />
          {this.state.data_mask}<br /><br />
          Se riconosci questo indirizzo email come tuo ed è ancora attivo, puoi utilizzarlo in questa form per proseguire con la registrazione.<br /><br />
          Se non riconosci l’indirizzo o non lo vuoi usare, devi rivolgerti al nostro servizio di assistenza per aggiornare l’indirizzo email associato al profilo.<br />
          Invia una mail a <a href="mailto:assistenza@accmed.org">assistenza@accmed.org</a> contenente i seguenti dati personali (nome, cognome, CF, Luogo e Data di Nascita), una scansione del tuo CF o CI, un numero di cellulare (per poterti contattare) e il nuovo indirizzo mail che vuoi associare all’account in questione.<br />
          Il nostro personale provvederà a contattarti e, confermata la tua identità e volontà di procedere, eseguirà il cambio dell’indirizzo email associato all’account.<br /><br />
          L’informativa sulla privacy per gli utenti dei servizi online di AccMed è raggiungibile facendo <a onClick={()=>this.setState({show_informativa:true})}>click qui.</a><br />
        </p>
        :
        <p className="esito_ok">
          Dear user, the tax code you specified is used by an account already existing on our servers in a suspended and minimized form due to a previous request for deletion of data (we have retained any minimum data in compliance with applicable regulations - such as the ECM). This account is associated with the email address:<br /><br />
          {this.state.data_mask}<br /><br />
          If you recognize this email address as yours and it is still active, you can use it in this form to continue with the registration.<br /><br />
          If you don't recognize the address or don't want to use it, you need to contact our support service to update the email address associated with your profile.<br />
          Send an email to <a href="mailto:assistenza@accmed.org">assistenza@accmed.org</a> containing the following personal data (name, surname, tax code, place and date of birth), a scan of your tax code or ID card, a mobile phone number (to be able to contact you) and the new email address you want to associate with the account in question.<br />
          Our staff will contact you and, having confirmed your identity and willingness to proceed, will change the email address associated with your account.<br /><br />
          The privacy policy for users of AccMed online services can be reached by <a onClick={()=>this.setState({show_informativa:true})}>clicking here.</a><br />
        </p>

    let step1 =
      <React.Fragment>
        <FormItem {...tailFormItemLayout}>
          <p><strong>{_l.step1}</strong></p>
        </FormItem>
        {nome_cognome}
        {codice_fiscale}
        {email}
        {conferma_email}
        {password}
        {modifica_password}
        {conferma_password}
        {bottone_continua}
        <FormItem {...tailFormItemLayout}>
          {esito_verifica}
        </FormItem>
      </React.Fragment>

    let step2 = ''
    if (this.state.stato_verifica===0) {

      let disabled = this.state.stato_registrazione ? true : false

      let stato =
        <FormItem {...formItemLayout} label={_l.stato} >
          {getFieldDecorator('stato', {
            initialValue: (this.state.user?this.state.user.stato:'IT'),
            rules: [{ required: true }],
          })(
            <Select disabled={disabled} showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0}>
              {this.state.select_stati}
            </Select>
          )}
        </FormItem>

      let province = ''
      let comuni = ''
      if (this.props.form.getFieldValue('stato')==='IT') {
        province =
          <FormItem {...formItemLayout} label={_l.provincia}>
            {getFieldDecorator('provincia', {
              initialValue: (this.state.user?this.state.user.provincia:''),
              rules: [{ required: true, message: _l.campo_obbligatorio, validator: this.validateChangeProvincia}],
            })(
              <Select disabled={disabled} showSearch optionFilterProp="children" onChange={this.handleChangeProvincia} filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0}>
                {this.state.select_province}
              </Select>
            )}
          </FormItem>

        if (this.props.form.getFieldValue('provincia'))
          comuni =
            <FormItem {...formItemLayout} label={_l.comune}>
              {getFieldDecorator('comune', {
                initialValue: (this.state.user?this.state.user.comune:''),
                rules: [{ required: true, message: _l.campo_obbligatorio, whitespace: true }],
              })(
                <Select disabled={disabled} showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0}>
                  {this.state.select_comuni}
                </Select>
              )}
            </FormItem>
      }

      let indirizzo =
        <FormItem {...formItemLayout} label={_l.indirizzo} >
          {getFieldDecorator('indirizzo', {
            initialValue: (this.state.user?this.state.user.indirizzo:''),
            rules: [{ required: true, message: _l.campo_obbligatorio, whitespace: true }],
          })(
            <Input disabled={disabled} />
          )}
        </FormItem>

      let cap =
        <FormItem {...formItemLayout} label={_l.cap} >
          {getFieldDecorator('zip_code', {
            initialValue: (this.state.user?this.state.user.zip_code:''),
            rules: [{ required: true, message: _l.campo_obbligatorio, whitespace: true }],
          })(
            <Input disabled={disabled} />
          )}
        </FormItem>

      let telefono =
        <FormItem {...formItemLayout} label={_l.cellulare} >
          {getFieldDecorator('cellulare', {
            initialValue: (this.state.user?this.state.user.cellulare:''),
            rules: [{
              required: true, message: _l.campo_obbligatorio, whitespace: true,
            },{
              validator: this.validateTelefono,
            }],
          })(
            <Input disabled={disabled} />
          )}
        </FormItem>

      let emailpec = ''
      let conferma_emailpec = ''
      if (this.props.ha_cf!=2) {
        emailpec =
          <FormItem {...formItemLayout} label={_l.pec} >
            {getFieldDecorator('email_pec', {
              initialValue: (this.state.user?this.state.user.email_pec:''),
              rules: [{
                type: 'email', message: 'E-mail PEC non valida',
              },{
                validator: this.validateToNextEmailPEC,
              }],
            })(
              <Input disabled={disabled} />
            )}
          </FormItem>
        /*
        if (this.props.form.getFieldValue('email_pec'))
          conferma_emailpec =
            <FormItem {...formItemLayout} label="Conferma E-mail PEC" >
              {getFieldDecorator('conferma_email_pec', {
                initialValue: (this.state.user?this.state.user.email_pec:''),
                rules: [{
                  required: true, message: _l.campo_obbligatorio,
                }, {
                  validator: this.compareToFirstEmailPEC,
                }],
              })(
                <Input disabled={disabled} onBlur={this.handleConfirmEmailPECBlur} />
              )}
            </FormItem>
          */
      }

      let professione =
        <FormItem {...formItemLayout} label={_l.professione} >
          {getFieldDecorator('professione', {
            initialValue: (this.state.user?this.state.user.professione:''),
            rules: [{required: true, message: _l.campo_obbligatorio, validator: this.validateChangeProf}],
          })(
            <Select disabled={disabled} showSearch optionFilterProp="children" onChange={this.handleChangeProf} filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0}>
              {this.state.select_prof}
            </Select>
          )}
        </FormItem>

      let discipline = ''
      if (this.props.form.getFieldValue('professione')) {
        discipline =
          <React.Fragment>
          <FormItem {...formItemLayout} label={_l.disciplina+' 1'}>
            {getFieldDecorator('disciplina1', {
              initialValue: (this.state.user?this.state.user.disciplina1:''),
              rules: [{ required: true, message: _l.campo_obbligatorio }],
            })(
              <Select disabled={disabled} showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0}>
                {this.state.select_disc}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label={_l.disciplina+' 2'}>
            {getFieldDecorator('disciplina2', {
              initialValue: (this.state.user?this.state.user.disciplina2:''),
            })(
              <Select disabled={disabled} showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0}>
                {this.state.select_disc}
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label={_l.disciplina+' 3'}>
            {getFieldDecorator('disciplina3', {
              initialValue: (this.state.user?this.state.user.disciplina3:''),
            })(
              <Select disabled={disabled} showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0}>
                {this.state.select_disc}
              </Select>
            )}
          </FormItem>
          </React.Fragment>
      }

      let posizione =
        <FormItem {...formItemLayout} label={_l.posizione} >
          {getFieldDecorator('posizione', {
            initialValue: (this.state.user?this.state.user.posizione:''),
            rules: [{ required: true, message: _l.campo_obbligatorio }],
          })(
            <Select disabled={disabled} showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0}>
              {this.state.select_posiz}
            </Select>
          )}
        </FormItem>

      let ente_nome =
        <FormItem {...formItemLayout} label={_l.ente_nome} >
          {getFieldDecorator('ente_nome', {
            initialValue: (this.state.user?this.state.user.ente_nome:''),
          })(
            <Input disabled={disabled} />
          )}
        </FormItem>

      let ente_divisione =
        <FormItem {...formItemLayout} label={_l.ente_divisione} >
          {getFieldDecorator('ente_divisione', {
            initialValue: (this.state.user?this.state.user.ente_divisione:''),
          })(
            <Input disabled={disabled} />
          )}
        </FormItem>

      /*
      let ente_citta =
        <FormItem {...formItemLayout} label={_l.ente_citta} >
          {getFieldDecorator('ente_citta', {
            initialValue: (this.state.user?this.state.user.ente_citta:''),
          })(
            <Input disabled={disabled} />
          )}
        </FormItem>
      */

      let ente_citta = ''
      let ente_provincia = ''
      if (this.props.form.getFieldValue('stato')==='IT') {
        ente_provincia =
          <FormItem {...formItemLayout} label={_l.ente_provincia}>
            {getFieldDecorator('ente_provincia', {
              initialValue: (this.state.user?this.state.user.ente_provincia:''),
              rules: [{ validator: this.validateChangeProvinciaEnte}],
            })(
              <Select disabled={disabled} showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0}>
                {this.state.select_province_ente}
              </Select>
            )}
          </FormItem>
        if (this.props.form.getFieldValue('ente_provincia'))
          ente_citta =
            <FormItem {...formItemLayout} label={_l.ente_citta} >
              {getFieldDecorator('ente_citta', {
                initialValue: (this.state.user?this.state.user.ente_citta:''),
              })(
                <Select disabled={disabled} showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0}>
                  {this.state.select_comuni_ente}
                </Select>
              )}
            </FormItem>
      }

      let esito_registrazione =
        <FormItem {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">{saving} {_l.conferma}</Button>
          <p className="assistenza">{_l.per_assistenza}<a href="mailto:assistenza@accmed.org">assistenza@accmed.org</a></p>
        </FormItem>

      if (this.state.stato_registrazione===1)
        esito_registrazione =
          <FormItem {...tailFormItemLayout}>
            <p className="esito_ko">
              {_l.ops2} <a href="mailto:assistenza@accmed.org">assistenza@accmed.org</a>
            </p>
        </FormItem>

      if (this.state.stato_registrazione===2 && this.state.new_user)
        esito_registrazione =
          <FormItem {...tailFormItemLayout}>
            {lang==='IT'?
            <p className="esito_ok">
              <strong>Registrazione completata con successo.</strong><br /><br />
              Controlla la casella di posta: a breve riceverai un messaggio automatico con richiesta di conferma registrazione. Per favore, segui le istruzioni in esso contenute.<br />
              Se non trovi la mail di conferma nella tua casella di posta elettronica, ti preghiamo di controllare con attenzione <strong>nella cartella SPAM.</strong><br />
              Se non hai ricevuto la mail di conferma <a onClick={()=>{document.location.href="?act=link"}}>fai click qui.</a><br />
              Dopo aver inviato la conferma il tuo account sarà attivato e potrai accedere a tutti i servizi on line di ACCMED utilizzando i dati di accesso da te scelti, per iscriverti alle attività residenziali e FAD o per scaricare i materiali didattici messi a disposizione.<br />
              Se necessiti di assistenza invia un messaggio a <a href="mailto:assistenza@accmed.org">assistenza@accmed.org</a>
            </p>
            :
            <p className="esito_ok">
              <strong>Registration successfully completed.</strong><br /><br />
              Check your inbox: soon you will receive an automatic message requesting the confirmation of the registration. Please, follow the instructions contained therein. <br />
              If you do not find the confirmation email in your inbox, please carefully check <strong>in the SPAM folder.</strong> <br />
              If you have not received the confirmation email <a onClick={()=>{document.location.href="?act=link"}}>click here.</a> <br />
              After submitting the confirmation, your account will be activated and you will be able to access all ACCMED online services using the access data you have specified, in particular you will be able to enroll in residential and e-learning activities and to download the didactic materials made available. <br />
              If you need assistance, please send a message to <a href="mailto:assistenza@accmed.org"> assistenza@accmed.org</a>
            </p>
            }
          </FormItem>

      if (this.state.stato_registrazione===2 && !this.state.new_user)
        esito_registrazione =
          <FormItem {...tailFormItemLayout}>
            <p className="esito_ok">
              {_l.dati_modificati}
            </p>
        </FormItem>

      let privacy =
        <FormItem {...tailFormItemLayout}>
          <Button className="rosso" onClick={ () => this.setState({mod_privacy: true}) }>{_l.modifica_privacy}</Button>
        </FormItem>

      if (this.state.mod_privacy)
        privacy =
          <React.Fragment>
            <FormItem {...tailFormItemLayout}>
              {getFieldDecorator('privacy', {
                initialValue: this.state.privacy,
                valuePropName: 'checked',
                rules: [{ required: true, validator: this.validatePrivacy }],
              })(
                <Checkbox disabled={disabled}><span className="ant-form-item-required">{_l.conferma_trattamento}</span></Checkbox>
              )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
              <p className="ant-form-item-required">{_l.presto_nego_promo}</p>
            </FormItem>
            <FormItem {...tailFormItemLayout}>
              {getFieldDecorator('promo', {
                initialValue: this.state.promo,
                rules: [{required: true, validator: this.validatePromo}],
              })(
                <RadioGroup>
                  <Radio value="1" disabled={disabled}>{_l.presto}</Radio>
                  <Radio value="0" disabled={disabled}>{_l.nego}</Radio>
                </RadioGroup>
              )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
              <p className="ant-form-item-required">{_l.presto_nego_abitudini}</p>
            </FormItem>
            <FormItem {...tailFormItemLayout}>
              {getFieldDecorator('abitudini', {
                initialValue: this.state.abitudini,
                rules: [{required: true, validator: this.validateAbitudini}],
              })(
                <RadioGroup>
                  <Radio value="1" disabled={disabled}>{_l.presto}</Radio>
                  <Radio value="0" disabled={disabled}>{_l.nego}</Radio>
                </RadioGroup>
              )}
            </FormItem>

            <FormItem {...tailFormItemLayout}>
              {getFieldDecorator('condizioni', {
                initialValue: this.state.condizioni,
                valuePropName: 'checked',
                rules: [{ required: true, validator: this.validatePrivacy }],
              })(
                <Checkbox disabled={disabled}><span className="ant-form-item-required">{_l.conferma_utilizzo}</span></Checkbox>
              )}
            </FormItem>
          </React.Fragment>

      let cancellami = ''
      if (!this.state.new_user) {
        cancellami =
          <FormItem {...tailFormItemLayout}>
            <hr />
            <p className="assistenza"><a href={"/cancellami/?lang="+lang}>{_l.cancellami}</a></p>
            <br />
          </FormItem>
      }

      step2 =
        <React.Fragment>
          <FormItem {...tailFormItemLayout}>
            <p><strong>{_l.step2}</strong></p>
            <p className="istruzioni">{_l.residenza}</p>
          </FormItem>
          {stato}
          {province}
          {comuni}
          {indirizzo}
          {cap}
          <FormItem {...tailFormItemLayout}>
            <p className="istruzioni">{_l.contatti}</p>
          </FormItem>
          {/*cellulare*/}
          {telefono}
          {emailpec}
          {conferma_emailpec}
          <FormItem {...tailFormItemLayout}>
            <p className="istruzioni">{_l.dati_professionali}<br/>{_l.nessuna_di_queste}</p>
          </FormItem>
          {professione}
          {discipline}
          {posizione}
          {ente_nome}
          {ente_divisione}
          {ente_provincia}
          {ente_citta}
          <FormItem {...tailFormItemLayout}>
            <p><strong>STEP 3: Privacy</strong></p>
          </FormItem>
          <FormItem {...tailFormItemLayout}>
            <Button onClick={ () => this.setState({show_informativa: true}) }>{_l.visualizza_trattamento}</Button>
          </FormItem>
          <FormItem {...tailFormItemLayout}>
            <Button onClick={ () => this.setState({show_condizioni: true}) }>{_l.visualizza_utilizzo}</Button>
          </FormItem>
          {privacy}
          {esito_registrazione}
          {cancellami}
        </React.Fragment>
    }

    return (
      <Form onSubmit={this.handleSubmit}>

        <FormItem {...tailFormItemLayout}>
          {label_gestione_profilo}
          <p className="istruzioni">{_l.compila_i_campi}</p>
        </FormItem>

        {step1}
        {step2}

        <Modal visible={this.state.show_informativa} style={{top:10}} onOk={this.handleOk} onCancel={this.handleCancel} footer={null}>
            <iframe title="privacy" className="iframe" src="https://www.accmed.org/informativa.html"></iframe>
        </Modal>

        <Modal visible={this.state.show_condizioni} style={{top:10}} onOk={this.handleOk} onCancel={this.handleCancel} footer={null}>
            <iframe title="condizioni" className="iframe" src="https://www.accmed.org/condizioniuso.html"></iframe>
        </Modal>

      </Form>
    );
  }
}

class RegistrationConfirm extends React.Component {

  constructor(props) {
    super(props);

    let r = <p>{_l.working} <Icon type="loading" theme="outlined" /></p>

    this.state = {
      risultato:r
    };
  }

  componentDidMount() {
      axios({
        method:'post',
        url:'services/dispatch.php',
        data: qs.stringify({servizio:'conferma_registrazione', token:this.props.token}),
        responseType:'text'
      })
      .then(function(response) {
        let risultato =
          <React.Fragment>
            {_l.ops2} <a href="mailto:assistenza@accmed.org">assistenza@accmed.org</a>
          </React.Fragment>
        if (response.data==='success') {
          risultato = _l.account_attivato
        }
        this.setState({risultato:risultato})
      }.bind(this))
      .catch(function (error) {
        warning(_l.ops, error.message);
        if (debug) console.log(error);
      })

  }

  render() {
    return (
      <React.Fragment>
        <div className="logo-div"><img alt="logo" className="logo-img" src={urlLogo} /></div>
        <h3>{_l.conferma_attivazione}</h3>
        {this.state.risultato}
      </React.Fragment>
    )
  }
}

class ForgotUsernameForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
    };
  }

  componentDidMount() {
  }

  sendRequest = (e) => {

    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
    if (!err) {
      if (this.state.saving) return
      this.setState({saving:true})
      axios({
        method:'post',
        url:'services/dispatch.php',
        data: qs.stringify({servizio:'forgot_username', codice_fiscale:values['codice_fiscale']}),
        responseType:'text'
      })
      .then(function(response) {
        if (response.data==='success') {
          warning(_l.controlla_email2)
        } else {
          warning(_l.cf_sconosciuto)
        }
        this.setState({saving:false})
      }.bind(this))
      .catch(function (error) {
        warning(_l.ops, error.message);
        if (debug) console.log(error);
      })
    }
    });
  }

  validateCF = (rule, value, callback) => {

    if (!value) {
      callback()
      return
    }

    let pattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
    if (value.length!==16)
      callback('Il codice fiscale deve essere lungo 16 caratteri');
    else
      if (value.search(pattern) === -1)
        callback('Il codice fiscale non è corretto');
      else {
        let cf = value.toUpperCase()
        //check codice di controllo
        let set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
        let s = 0;
        for (let i = 1; i <= 13; i += 2) s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
        for (let i = 0; i <= 14; i += 2) s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
        if (s % 26 != cf.charCodeAt(15) - 'A'.charCodeAt(0))
          callback ('Il codice fiscale non è corretto');
        else
          callback();
      }
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    let saving = false
    if (this.state.saving) saving = <Icon type="loading" theme="outlined" />

    return (
      <React.Fragment>
        <h3>{_l.username_dimenticato}</h3>
        <h4>{_l.inserisci_cf}</h4>
        <Form onSubmit={this.sendRequest}>
          <FormItem>
            {getFieldDecorator('codice_fiscale', {
              rules: [{
                required: true, message: _l.inserisci_cf,
              }, {
                validator: this.validateCF,
              }],
            })(
              <Input />
            )}
          </FormItem>
          <FormItem>
            <Button type="primary" htmlType="submit">{saving} {_l.invia}</Button>
          </FormItem>
        </Form>
      </React.Fragment>
    );
  }
}

class ForgotPasswordForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data:[],
      saving: false,
      disabled1: true,
      disabled2: true,
      confirmDirty: false,
      emailSent: false,
      azione: '',
    };
  }

  componentDidMount() {
    if (this.props.token) {
      axios({
        method:'post',
        url:'services/dispatch.php',
        data: qs.stringify({servizio:'check_token_password', token:this.props.token}),
        responseType:'text'
      })
      .then(function(response) {
        if (response.data) {
          this.setState({azione:'changePassword', data:response.data})
        } else {
          this.setState({azione:'changeRequest'})
          warning(_l.link_scaduto)
        }
      }.bind(this))
      .catch(function (error) {
        warning(_l.ops, error.message);
        if (debug) console.log(error);
      })
    } else {
      this.setState({azione:'changeRequest'})
    }
  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      this.setState({disabled1:true})
      callback(_l.password_coincidere);
    } else {
      this.setState({disabled1:false})
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {

    //let regole = new RegExp(/^(?=.*\d.*\d.*\d)(?=.*[A-Za-z@#$%])[0-9a-zA-Z@#$%]{8,}$/);
    let regole = new RegExp(/^(?=.*\d.*\d)(?=.*[A-Za-z!?£$€&#@%])[0-9a-zA-Z!?£$€&#@%]{8,}$/);

    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['conferma_password'], { force: true });
    }
    if (value && !regole.exec(value)) {
      this.setState({disabled2:true})
      callback(_l.regole_password);
    } else {
      this.setState({disabled2:false})
      callback();
    }
  }

  changeRequest = (e) => {

    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
    if (!err) {
      if (this.state.saving) return
      this.setState({saving:true})
      axios({
        method:'post',
        url:'services/dispatch.php',
        data: qs.stringify({servizio:'forgot_password', email:values['email']}),
        responseType:'text'
      })
      .then(function(response) {

        if (response.data) {
          this.setState({emailSent:true})
          warning(_l.controlla_email)
        }
        else
          warning(_l.email_link_sconosciuti)

        this.setState({saving:false})
      }.bind(this))
      .catch(function (error) {
        warning(_l.ops, error.message);
        if (debug) console.log(error);
      })
    }
    });
  }

  changePassword = (e) => {

    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
    if (!err) {
      if (this.state.saving) return
      this.setState({saving:true})
      axios({
        method:'post',
        url:'services/dispatch.php',
        data: qs.stringify({servizio:'change_password', password:values['password'], id:this.state.data}),
        responseType:'text'
      })
      .then(function(response) {
        if (response.data==='success') {
          this.setState({azione:'finalPage'})
        } else {
          warning(_l.ops + _l.riprova)
        }
        this.setState({saving:false})
      }.bind(this))
      .catch(function (error) {
        warning(_l.ops, error.message);
        if (debug) console.log(error);
      })
    }
    });

  }

  render() {

    const { getFieldDecorator } = this.props.form;

    let saving = false
    if (this.state.saving) saving = <Icon type="loading" theme="outlined" />

    let invia = <Button type="primary" disabled>{_l.invia}</Button>
    if (!this.state.disabled1 && !this.state.disabled2) invia = <Button type="primary" htmlType="submit">{saving} {_l.invia}</Button>

    let the_form = '';

    if (this.state.azione==='changePassword') {
      the_form =
        <div>
        <div className="logo-div"><img alt="logo" className="logo-img" src={urlLogo} /></div>
        <h3>{_l.cambio_password}</h3>
        <Form onSubmit={this.changePassword}>
          <FormItem>
            {getFieldDecorator('password', {
              rules: [{
                required: true, message: _l.campo_obbligatorio,
              }, {
                validator: this.validateToNextPassword,
              }],
            })(
              <Input.Password type="password" placeholder={_l.digita_nuova_password} />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('conferma_password', {
              rules: [{
                required: true, message: _l.campo_obbligatorio,
              }, {
                validator: this.compareToFirstPassword,
              }],
            })(
              <Input.Password type="password" onBlur={this.handleConfirmBlur} placeholder={_l.conferma_nuova_password} />
            )}
          </FormItem>
          <FormItem>
            {invia}
          </FormItem>
        </Form>
        </div>
    }
    if (this.state.azione==='changeRequest') {

      if (!this.state.emailSent) invia = <Button type="primary" htmlType="submit">{saving} {_l.invia}</Button>

      the_form =
        <div>
        <h3>{_l.cambio_password}</h3>
        <h4>{_l.link_cambio_password}</h4>
        <Form onSubmit={this.changeRequest}>
          <FormItem>
            {getFieldDecorator('email', {
              rules: [{
                type: 'email', message: _l.email_non_valida,
              }, {
                required: true, message: _l.campo_obbligatorio,
              }],
            })(
              <Input prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} />
            )}
          </FormItem>
          <FormItem>
            {invia}
          </FormItem>
        </Form>
        </div>
    }
    if (this.state.azione==='finalPage') {
      the_form =
        <div>
          <div className="logo-div"><img alt="logo" className="logo-img" src={urlLogo} /></div>
          <h3>{_l.cambio_password}</h3>
          {lang==='IT' ?
          <React.Fragment>
            <p>La tua password è stata modificata con successo. Grazie!</p>
            <p>Clicca qui per visitare <a href="https://www.accmed.org" target="_blank" rel="noopener noreferrer">www.accmed.org</a><br />oppure il nostro portale di formazione a distanza <a href="https://fad.accmed.org" target="_blank" rel="noopener noreferrer">fad.accmed.org</a></p>
          </React.Fragment>
          :
          <React.Fragment>
            <p>Your password has been successfully changed. Thank you!</p>
            <p>Click here to visit <a href="https://www.accmed.org" target="_blank" rel="noopener noreferrer">www.accmed.org</a><br />or our e-learning portal <a href="https://fad.accmed.org" target="_blank" rel="noopener noreferrer">fad.accmed.org</a></p>
          </React.Fragment>
          }
        </div>
    }
    return (
      <React.Fragment>
        {the_form}
      </React.Fragment>
    );
  }
}

class ForgotLinkForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data:[],
      saving: false,
      emailSent: false,
    };
  }

  getRequest = (e) => {

    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
    if (!err) {
      if (this.state.saving) return
      this.setState({saving:true})
      axios({
        method:'post',
        url:'services/dispatch.php',
        data: qs.stringify({servizio:'forgot_link', email:values['email']}),
        responseType:'text'
      })
      .then(function(response) {
        if (response.data) {
          this.setState({emailSent:true})
          if (response.data==='success') warning(_l.controlla_email)
          if (response.data==='no_email') warning(_l.email_sconosciuta)
          if (response.data==='gia_attivo') warning(_l.account_gia_attivato)
        } else {
          warning(_l.ops)
        }
        this.setState({saving:false})
      }.bind(this))
      .catch(function (error) {
        warning(_l.ops, error.message);
        if (debug) console.log(error);
      })
    }
    });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    let saving = false
    if (this.state.saving) saving = <Icon type="loading" theme="outlined" />

    let invia = <Button type="primary" disabled>{_l.invia}</Button>

    if (!this.state.emailSent) invia = <Button type="primary" htmlType="submit">{saving} {_l.invia}</Button>

    return (
      <div>
        <h3>{_l.label_link_attivazione}</h3>
        <h4>{_l.link_attivazione}</h4>
        <Form onSubmit={this.getRequest}>
          <FormItem>
            {getFieldDecorator('email', {
              rules: [{
                type: 'email', message: _l.email_non_valida,
              }, {
                required: true, message: _l.campo_obbligatorio,
              }],
            })(
              <Input prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} />
            )}
          </FormItem>
          <FormItem>
            {invia}
          </FormItem>
        </Form>
      </div>
    );
  }
}

class ChangeEmailForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      get_cf: true,
      get_otp: false,
      get_email: false,
      finale: false,
      cellulare: '',
      tentativi: 1,
      azione: 'getCF',
      new_email: '',
      //restart: false,
      codice_fiscale: '',
      recaptcha_response: 'no',
      send_reminder: true,
    };

  }

  componentDidUpdate() {
   let el1 = document.getElementById("conferma_email")
   if (el1) el1.addEventListener("paste", function(e) { e.preventDefault(); })
  }

  sendOTP = (e) => {

    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
    if (!err) {
      if (this.state.saving) return
      this.setState({saving:true})
      let codice_fiscale =  values['codice_fiscale']
      let recaptcha_response = 'no';
      if (this.state.azione==='newOTP') {
        if (this.state.recaptcha_response==='si') {
          warning('Seleziona "Non sono un robot", per favore.');
          this.setState({saving:false})
          return
        } else {
          recaptcha_response = this.state.recaptcha_response
        }
      }

      let servizio = this.props.from==='opbg' ? 'send_otp_opbg' : 'send_otp';

      axios({
        method:'post',
        url:'services/dispatch.php',
        data: qs.stringify({servizio:servizio, codice_fiscale:codice_fiscale, recaptcha_response:recaptcha_response}),
        responseType:'text'
      })
      .then(function(response) {
        let r = response.data
        if (r.stato==='success') this.setState({azione:'getOTP',cellulare:r.cellulare})
        if (r.stato==='no-tentativi') warning(_l.no_tentativi)
        if (r.stato==='no-account') warning(_l.cf_sconosciuto)
        if (r.stato==='no-cf') warning(_l.cf_sconosciuto)
        if (r.stato==='no-cel') warning(_l.no_cel)
        if (r.stato==='no-otp') warning(_l.no_otp)
        if (r.stato==='ldap') warning("Sei un dipendente OPBG, non puoi aggiornare l'email")
        //setTimeout(()=>{this.setState({restart:true})}, 1000);
        this.setState({saving:false, codice_fiscale:codice_fiscale})
      }.bind(this))
      .catch(function (error) {
        warning(_l.ops, error.message);
        if (debug) console.log(error);
      })
    }
    });
  }

  checkOTP = (e) => {

    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
    if (!err) {
      if (this.state.saving) return
      this.setState({saving:true})
      let servizio = this.props.from==='opbg' ? 'check_otp_opbg' : 'check_otp';
      axios({
        method:'post',
        url:'services/dispatch.php',
        data: qs.stringify({servizio:servizio, codice_otp:values['codice_otp']}),
        responseType:'text'
      })
      .then(function(response) {
        let r = response.data
        if (r.stato==='success') this.setState({azione:'getEMAIL'})
        if (r.stato==='failure') {
          warning(_l.otp_errato)
          if (this.state.tentativi===3)
            this.setState({azione:'newOTP', recaptcha_response:'si', tentativi:1})
          else {
            this.setState({tentativi:this.state.tentativi+1})
          }
        }
        this.setState({saving:false})
      }.bind(this))
      .catch(function (error) {
        warning(_l.ops, error.message);
        if (debug) console.log(error);
      })
    }
    });
  }

  changeEmail = (e) => {

    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
    if (!err) {
      if (this.state.saving) return
      this.setState({saving:true})
      let servizio = this.props.from==='opbg' ? 'change_email_opbg' : 'change_email';
      axios({
        method:'post',
        url:'services/dispatch.php',
        data: qs.stringify({servizio:servizio, email:values['email']}),
        responseType:'text'
      })
      .then(function(response) {
        let r = response.data
        if (r.stato==='success') this.setState({azione:'finalPage', new_email:values['email']})
        if (r.stato==='failure') {
          if (r.codice==='sel-sia1')
            warning(_l.email_presente)
          else
            warning(_l.ops)
        }
        this.setState({saving:false})
      }.bind(this))
      .catch(function (error) {
        warning(_l.ops, error.message);
        if (debug) console.log(error);
      })
    }
    });
  }

  sendReminder = () => {

    if (this.state.saving) return

    this.setState({saving:true})
    let servizio = this.props.from==='opbg' ? 'reminder_change_email_opbg' : 'reminder_change_email';
    axios({
      method:'post',
      url:'services/dispatch.php',
      data: qs.stringify({servizio:servizio, email:this.state.new_email}),
      responseType:'text'
    })
    .then(function(response) {
      this.setState({saving:false, send_reminder:false})
    }.bind(this))
    .catch(function (error) {
      warning(_l.ops, error.message);
      if (debug) console.log(error);
    })
  }

  validateCF = (rule, value, callback) => {

    if (!value) {
      callback()
      return
    }

    let pattern = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
    if (value.length!==16)
      callback('Il codice fiscale deve essere lungo 16 caratteri');
    else
      if (value.search(pattern) === -1)
        callback('Il codice fiscale non è corretto');
      else {
        let cf = value.toUpperCase()
        //check codice di controllo
        let set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
        let s = 0;
        for (let i = 1; i <= 13; i += 2) s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
        for (let i = 0; i <= 14; i += 2) s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
        if (s % 26 != cf.charCodeAt(15) - 'A'.charCodeAt(0))
          callback ('Il codice fiscale non è corretto');
        else
          callback();
      }
  }

  validateOTP = (rule, value, callback) => {

    if (!value) {
      callback()
      return
    }
    if (isNaN(value)) {
      callback('Il codice temporaneo è composto da soli numeri')
      return
    }
    if (value.length!==6) {
      callback('Il codice temporaneo è composta da 6 numeri')
      return
    }
    callback()

  }

  handleConfirmEmailBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmEmailDirty: this.state.confirmEmailDirty || !!value });
  }

  compareToFirstEmail = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('email')) {
      callback(_l.email_coincidere);
    } else {
      callback();
    }
  }

  validateToNextEmail = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmEmailDirty) {
      form.validateFields(['conferma_email'], { force: true });
    }
    callback();
  }

  onChangeRecaptcha = (v) => {
    this.setState({recaptcha_response: v});
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    let saving = false
    if (this.state.saving) saving = <Icon type="loading" theme="outlined" />

    let colore_from = this.props.from==='opbg' ? 'colore-opbg' : ''

    let form_data = null
    if (this.state.azione==='getCF')
      form_data =
        <React.Fragment>
          <h4 dangerouslySetInnerHTML={createMarkup(_l.help_cambia_email)} />
          <Form onSubmit={this.sendOTP}>
            <FormItem>
              {getFieldDecorator('codice_fiscale', {
                rules: [{
                  required: true, message: _l.inserisci_cf,
                }, {
                  validator: this.validateCF,
                }],
              })(
                <Input />
              )}
            </FormItem>
            <FormItem>
              <Button className={colore_from} type="primary" htmlType="submit">{saving} {_l.invia}</Button>
            </FormItem>
          </Form>
        </React.Fragment>

    if (this.state.azione==='getOTP')
      form_data =
        <React.Fragment>
          <h4 dangerouslySetInnerHTML={createMarkup(_l.help_usa_otp.replace("$cellulare$", this.state.cellulare))} />
          <Form onSubmit={this.checkOTP}>
            <FormItem>
              {getFieldDecorator('codice_otp', {
                rules: [{
                  required: true, message: _l.inserisci_otp,
                }, {
                  validator: this.validateOTP,
                }],
              })(
                <Input maxLength={6} />
              )}
            </FormItem>
            <FormItem>
              <Button className={colore_from} type="primary" htmlType="submit">{saving} {_l.invia}</Button>
              <Button type="link" onClick={()=>{this.setState({azione:'getCF'})}}>{_l.restart_change_email_2}</Button>
            </FormItem>
          </Form>
        </React.Fragment>

    if (this.state.azione==='newOTP')
      form_data =
        <React.Fragment>
          <h4>{_l.inserisci_cf}</h4>
          <h4 dangerouslySetInnerHTML={createMarkup(_l.help_cambia_email)} />
          <Form onSubmit={this.sendOTP}>
            <FormItem>
              {getFieldDecorator('codice_fiscale', {
                initialValue:this.state.codice_fiscale,
                rules: [{
                  required: true, message: _l.inserisci_cf,
                }, {
                  validator: this.validateCF,
                },],
              })(
                <Input />
              )}
            </FormItem>
            <FormItem>
              <div className="recaptcha">{_l.sei_umano}</div>
              <div className="recaptcha"><ReCAPTCHA sitekey={recaptcha_publickey} onChange={this.onChangeRecaptcha} /></div>
              <Button className={colore_from} type="primary" htmlType="submit">{saving} {_l.invia}</Button>
            </FormItem>
          </Form>
        </React.Fragment>

    if (this.state.azione==='getEMAIL')
      form_data =
        <React.Fragment>
          <h4>{_l.help_cambia_email_2}</h4>
          <Form onSubmit={this.changeEmail}>
            <FormItem label="E-mail" >
              {getFieldDecorator('email', {
                rules: [{
                  type: 'email', message: _l.email_non_valida,
                }, {
                  required: true, message: _l.campo_obbligatorio,
                }, {
                  validator: this.validateToNextEmail,
                }],
              })(
                <Input />
              )}
            </FormItem>
            <FormItem label={_l.conferma_email} >
              {getFieldDecorator('conferma_email', {
                rules: [{
                  required: true, message: _l.campo_obbligatorio,
                }, {
                  validator: this.compareToFirstEmail,
                }],
              })(
              <Input onBlur={this.handleConfirmEmailBlur} />
              )}
            </FormItem>
            <FormItem>
              <Button className={colore_from} type="primary" htmlType="submit">{saving} {_l.invia}</Button>
            </FormItem>
          </Form>
        </React.Fragment>

    let finale = null
    if (this.state.azione==='finalPage') {
      let btn_reminder = this.state.send_reminder ? <Button className={colore_from} type="primary" onClick={()=>{this.sendReminder()}}>{saving} {_l.reminder_change_email}</Button> : null
      finale =
        <div>
          <div className="mb-1">{_l.email_aggiornata}</div>
          <div>{_l.email_aggiornata_2}</div>
          <div className='mb-1 rosso'><strong>{this.state.new_email}</strong></div>
          <div>{_l.email_aggiornata_3}</div>
          <div className='mb-1 rosso'><strong>{this.state.new_email}</strong></div>
          {btn_reminder}
          {/*<Button onClick={()=>{this.setState({azione:'getCF'})}}>{_l.restart_change_email_2}</Button>*/}
        </div>
    }

    return (
      <React.Fragment>
        <h3>{_l.cambia_email}</h3>
        {form_data}
        {finale}
      </React.Fragment>
    );
  }
}

function createMarkup(html) { return {__html: html} }

function initLang(l) {

  var IT = {
    si: 'Sì',
    no: 'No',
    invia: 'Invia',
    inizia: 'Inizia',
    procedi: 'Procedi',
    conferma: 'Conferma',
    continua: 'Continua',
    indietro: 'Torna indietro',
    u18: 'meno di 18',
    o18: '18 o più',
    hai_cf: 'Hai un Codice Fiscale italiano?<sup>**</sup>',
    hai_eta: 'Quanti anni hai?<sup>*</sup>',
    rispondi: 'Per favore, rispondi alle seguenti domande per poter procedere.',
    attenzione_nocf: 'Attenzione! Per le attività accreditate ECM, i crediti sono erogati esclusivamente a operatori sanitari residenti in Italia, dotati di Codice Fiscale italiano. Per procedere devi confermare di aver compreso e accettato questa limitazione.',
    dichiaro_nocf: 'Dichiaro di comprendere e accettare questa limitazione relativa all’erogazione dei crediti ECM.',
    under18: 'L’accesso ai servizi online di Accademia Nazionale di Medicina è riservato ai maggiori di 18 anni.',
    accesso: '<sup>*</sup> L’accesso ai servizi online di Accademia Nazionale di Medicina è riservato ai maggiori di 18 anni.<br /><sup>**</sup> Per le attività accreditate ECM, i crediti sono erogati esclusivamente a operatori sanitari dotati di Codice Fiscale italiano.',
    ottimizzato: 'Il sito è ottimizzato per funzionare con Firefox, Safari e Chrome.',
    step1: 'STEP 1: Dati Principali',
    step2: 'STEP 2: Dati personali e professionali',
    contatti: 'Contatti',
    dati_professionali: 'Dati professionali',
    nome: 'Nome',
    cognome: 'Cognome',
    cf: 'Codice fiscale',
    stato: 'Stato',
    provincia: 'Provincia',
    comune: 'Comune',
    indirizzo: 'Indirizzo',
    residenza: 'Indirizzo di Residenza',
    cap: 'CAP',
    cellulare: 'Cellulare',
    telefono_no: 'Numero telefonico non valido',
    professione: 'Professione',
    disciplina: 'Disciplina',
    posizione: 'Posizione lavorativa',
    ente_nome: 'Ente',
    ente_divisione: 'Divisione/Struttura',
    ente_citta: 'Città Ente',
    ente_provincia: 'Provincia Ente',
    nessuna_di_queste: 'Se la tua professione non compare nella lista, seleziona la voce "Nessuna di queste"',
    regole_nome: "I campi Nome e Cognome possono contenere solo lettere e i caratteri: apostrofo ('), trattino (-) e spazio ( )",
    regole_password: 'La password deve contenere almeno 8 caratteri di cui 2 devono essere numeri. Sono ammessi i seguenti caratteri speciali: ! ? £ $ € & # @ %',
    password_coincidere: 'le password devono coincidere',
    email_coincidere: 'le email devono coincidere',
    conferma_email: 'Conferma E-mail',
    conferma_password: 'Conferma la password',
    modifica_password: 'Modifica password',
    modifica_privacy: 'Modifica le tue impostazioni privacy',
    visualizza_trattamento: 'Visualizza "Informativa trattamento dati"',
    visualizza_utilizzo: 'Visualizza "Condizioni di utilizzo"',
    conferma_trattamento: 'Confermo di aver preso visione dell’informativa sul trattamento dei dati personali.',
    conferma_utilizzo: 'Confermo di aver letto le condizioni di utilizzo e di accettarle.',
    conferma_obbligatoria: 'Conferma obbligatoria',
    presto_nego_promo: 'Presto/Nego il consenso al trattamento dei miei dati personali per svolgere attività di promozione attraverso l’invio di comunicazioni personali e la realizzazione di analisi di settore.',
    presto_nego_abitudini: 'Presto/Nego il consenso al trattamento dei miei dati personali per analizzare le abitudini e i comportamenti dell’interessato nel contesto dei servizi online forniti dal Titolare al fine di migliorare i servizi esistenti e proporre nuovi servizi.',
    presto: 'Presto il consenso',
    nego: 'Nego il consenso',
    presta_nega: 'Presta o nega il tuo consenso',
    ops: 'Ops, qualcosa è andato storto...',
    riprova: 'Riprova più tardi.',
    ops2: 'Ops, qualcosa è andato storto... contattaci a questo indirizzo:',
    compila_i_campi: 'Compila i campi, con particolare attenzione a quelli obbligatori, che sono contraddistinti dal simbolo "*"',
    campo_obbligatorio: 'Campo obbligatorio',
    per_assistenza: 'Per assistenza invia una richiesta a ',
    email_assistenza_ok: 'Email di richiesta assistenza inviata.',
    dati_modificati: 'Dati modificati con successo.',
    working: 'Elaborazione in corso...',
    conferma_attivazione: 'Conferma attivazione account',
    account_attivato: 'Il tuo account è stato attivato. Ora puoi accedere ai servizi di Accademia nazionale di Medicina.',
    link_scaduto: 'Link scaduto, ripeti la richiesta',
    controlla_email: 'Controlla la tua casella email e segui le istruzioni contenute nel messaggio automatico che riceverai a breve.',
    controlla_email2: 'Controlla la tua casella email, ti abbiamo inviato un messaggio.',
    email_sconosciuta: 'L\'indirizzo email inserito non appartiene a nessun utente registrato.',
    email_link_sconosciuti: 'Indirizzo email errato o account non ancora attivato.',
    email_non_valida: 'E-mail non valida',
    cambio_password: 'Cambio password',
    digita_nuova_password: 'Digita la nuova password',
    conferma_nuova_password: 'Conferma la nuova password',
    link_cambio_password: 'Inserisci la tua email di registrazione e premi su "Invia". Il link per il cambio password verrà inviato alla tua casella di posta.',
    account_gia_attivato: 'L\'account risulta già attivato.',
    label_link_attivazione: 'Link attivazione account',
    link_attivazione: 'Inserisci la tua email di registrazione e premi su "Invia". Il link di attivazione verrà inviato alla tua casella di posta.',
    gestione_profilo: 'Gestione account AccMed Online',
    creazione_profilo: 'Creazione account AccMed Online',
    username_dimenticato: 'Username dimenticato',
    inserisci_cf: 'Inserisci il tuo codice fiscale e premi su "Invia".',
    cf_sconosciuto: 'Il codice fiscale inserito non appartiene a nessun utente registrato.',
    cancellami: 'Cancella questo account',
    pec: 'Indirizzo PEC',
    sei_umano: "Sei umano? Clicca qui sotto, grazie!",
    cambia_email: "Aggiornamento indirizzo Email",
    no_tentativi: "Siamo spiacenti, hai raggiunto il numero massimo di tentativi permessi per l'invio del codice di verifica. Contatta l'assistenza all'indirizzo assistenzafad@accmed.org",
    no_cel: "L’account associato al codice fiscale inserito non contiene un numero di cellulare. Per aggiornare il tuo indirizzo email, contatta l'assistenza (assistenzafad@accmed.org).",
    no_otp: "Si è verificato un errore nell'invio del codice di verifica. Per aggiornare il tuo indirizzo email, contatta l'assistenza (assistenzafad@accmed.org).",
    inserisci_otp: 'Per procedere, inserisci il codice di verifica e fai click su "Invia".',
    help_cambia_email: "Per confermare la tua identità e procedere con l’aggiornamento dell’indirizzo email, invieremo un SMS con un codice temporaneo di verifica al numero di cellulare specificato in fase di registrazione.<br><br><b>Inserisci il tuo Codice Fiscale e fai click su “Invia” per proseguire</b>.",
    help_cambia_email_2: "Inserisci e conferma il nuovo indirizzo email che vuoi utilizzare.",
    help_usa_otp: "Abbiamo inviato un SMS con il codice di verifica al numero $cellulare$. Il codice è valido per 5 minuti.<br><br><b>Inserisci il codice ricevuto e premi “Invia” per proseguire:</b>",
    otp_errato: "Codice di verifica errato o scaduto, per favore riprova.",
    email_presente: "Questo indirizzo email è già presente nel nostro sistema. Specificare un indirizzo email differente.",
    email_aggiornata: "L’indirizzo email del tuo account è stato aggiornato con successo.",
    email_aggiornata_2: "Il tuo nuovo indirizzo email:",
    email_aggiornata_3: "La tua nuova username:",
    reminder_change_email: "Invia promemoria via email",
    restart_change_email: "Richiedi un nuovo codice",
    restart_change_email_2: "Riavvia questa procedura",
  };

  var EN = {
    si: 'Yes',
    no: 'No',
    invia: 'Send',
    inizia: 'Start',
    procedi: 'Next step',
    conferma: 'Confirm',
    continua: 'Continue',
    indietro: 'Go back',
    u18: 'less than 18 ',
    o18: '18 or more',
    hai_cf: 'Do you have an Italian Tax Code?<sup>**</sup>',
    hai_eta: 'How old are you?<sup>*</sup>',
    rispondi: 'Please answer the following questions in order to proceed.',
    attenzione_nocf: 'Warning! For ECM accredited activities, credits are provided exclusively to healthcare professionals resident in Italy, with an Italian CF. To proceed you must confirm that you have understood and accepted this limitation.',
    dichiaro_nocf: 'I declare that I understand and accept this limitation on the provision of ECM credits.',
    under18: 'Access to the online services of the National Academy of Medicine is reserved for people over 18 years of age.',
    accesso: '<sup>*</sup>Access to the online services of the National Academy of Medicine is reserved for people over 18 years of age.<br /><sup>**</sup>For ECM accredited activities, credits are provided exclusively to healthcare professionals with an Italian CF.',
    ottimizzato: 'The site is optimized to work with Firefox, Safari and Chrome.',
    step1: 'STEP 1: Main data',
    step2: 'STEP 2: Personal and professional data',
    contatti: 'Contacts',
    dati_professionali: 'Professional information',
    nome: 'Name',
    cognome: 'Surname',
    cf: 'Italian Tax Code',
    stato: 'Country',
    provincia: 'Province',
    comune: 'City',
    indirizzo: 'Address',
    residenza: 'Home address',
    cap: 'Zip Code',
    cellulare: 'Cell Phone',
    telefono_no: 'Invalid phone number',
    professione: 'Profession',
    disciplina: 'Discipline',
    posizione: 'Work Position',
    ente_nome: 'Organization',
    ente_divisione: 'Department',
    ente_citta: 'City (Organization)',
    ente_provincia: 'Province (Organization)',
    nessuna_di_queste: 'If you profession does not appear in the list, select "None of these".',
    regole_nome: "Name and Surname can contain only letters and the characters: apostrophe ('), dash (-) and space ( )",
    regole_password: 'The password must contain: at least 8 characters, 2 must be digits. The following special characters are allowed:  ! ? £ $ € & # @ %',
    password_coincidere: 'Provided passwords must be equal',
    email_coincidere: 'Provided e-mail addresses must be equal',
    conferma_email: 'Confirm E-mail',
    conferma_password: 'Confirm password',
    modifica_password: 'Change password',
    modifica_privacy: 'Change the privacy settings',
    visualizza_trattamento: 'Visualize "Data processing policy"',
    visualizza_utilizzo: 'Visualize "Users\' conditions"',
    conferma_trattamento: 'I confirm I read the "personal data processing policy"',
    conferma_utilizzo: 'I confirm I read the "Users\' conditions" and to accept them.',
    conferma_obbligatoria: 'Confirmation is mandatory',
    presto_nego_promo: 'I give/deny the consent to process my personal data for the purpose of promoting activities through personal messages and of performing sectorial data analysis.',
    presto_nego_abitudini: 'I give/deny the consent to process my personal data for the purpose of analysising my online usage pattern and behaviours in the frame of the online services provided by the Company in order to improve its existing service and develop new ones.',
    presto: 'Give your consent',
    nego: 'Deny your consent',
    presta_nega: 'Give or deny your consent',
    ops: 'Ops, something went wrong...',
    riprova: 'Try later',
    ops2: 'Ops, something went wrong.. Contact us at this e-mail address:',
    compila_i_campi: 'Fill-in the fields, with specific attention for the required field, marked with a "*" mark',
    campo_obbligatorio: 'Required field',
    per_assistenza: 'To receive support, please write your request to ',
    email_assistenza_ok: 'The e-mail with your support request was sent.',
    dati_modificati: 'Data updated successfully',
    working: 'Processing...',
    conferma_attivazione: 'Confirmation of account activation',
    account_attivato: 'Your account is now active. You can access the online services of AccMed.',
    link_scaduto: 'Expired link, please ask for a new one.',
    controlla_email: 'Please check your e-mail inbox and follow the instructions provided in the message we sent to you.',
    controlla_email2: 'Please check your e-mail inbox, we sent you a message.',
    email_sconosciuta: 'The provided e-mail address does not match any existing account.',
    email_link_sconosciuti: 'The provided e-mail address does not match any existing account.',
    email_non_valida: 'Invalid E-mail',
    cambio_password: 'Change password',
    digita_nuova_password: 'Please write your password',
    conferma_nuova_password: 'Please confirm your password',
    link_cambio_password: 'Please insert the email you registred with and press "Send". A link to activate the account will be sent to your e-mail address.',
    account_gia_attivato: 'This account is already active.',
    label_link_attivazione: 'Link to activate the account',
    link_attivazione: 'Please insert the email you registred with and press "Send". A link to change the password will be sent to your e-mail address.',
    gestione_profilo: 'Manage AccMed Online Account',
    creazione_profilo: 'Create your AccMed Online account',
    username_dimenticato: 'Forgotten your username?',
    inserisci_cf: 'Please insert your IT fiscal code and press the "Send" button',
    cf_sconosciuto: 'The provided Italian Tax Code does not match any existing account.',
    cancellami: 'Delete this account',
    pec: 'PEC Address',
    sei_umano: "Sei umano? Clicca qui sotto, grazie!",
    cambia_email: "Email Address Update",
    no_tentativi: "Sorry, you reached the maximum number of allowed verification code requests. Please contact the support service: assistenzafad@accmed.org",
    no_cel: "Your account has no associated cell phone number. To update your email address, please contact our user support service (assistenza@accmed.org).",
    no_otp: "An error has occurred while sending the verification code. To update your email address, please contact our user support service (assistenzafad@accmed.org).",
    inserisci_otp: 'To proceed, please insert the verification code and click "Proceed"',
    help_cambia_email: "To proceed with the email address update procedure, we will send you a SMS containing a temporary verification code. The code will expire after 5 minutes.<br><br><b>Please insert your IT fiscal code and press the “Send“ button</b>.",
    help_cambia_email_2: "Please insert and confirm the email address you want to use.",
    help_usa_otp: "We sent a SMS containing the verification code to the number $cellulare$. The code is valid for the next 5 minutes.<br><br><b>Insert the verification code and press “Send Code”</b>.",
    otp_errato: "Verification code wrong or expired, please try again.",
    email_presente: "This email address is already present in our system. Please use a different email address.",
    email_aggiornata: "Your email address has been successfully updated.",
    email_aggiornata_2: "Your new email address is:",
    email_aggiornata_3: "Your new username is:",
    reminder_change_email: "Send email recap",
    restart_change_email: "Request a new code",
    restart_change_email_2: "Restart this procedure",
  };

  //lang = window.navigator.userLanguage || window.navigator.language;
  lang = l; //lang.indexOf("it")!=-1 ? 'IT' : 'EN';
  _l = lang=='IT' ? IT : EN;

}

var _l = [];
var lang = 'IT';

export default App;
